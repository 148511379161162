import $ from "jquery";
import { Message, MessageBox } from "element-ui";
import config from "./config";
import units from "../common/units.js";
import store from "../store/index";
import rules from "../common/js/rules";
import Qs from "qs";

export function get(url, data, params) {
  let url2 = "";
  if (localStorage.getItem("token")) {
    if (params) {
      url2 = config.BaseUrl + "/" + url + "&" + Qs.stringify(params);
    } else {
      url2 = config.BaseUrl + "/" + url
    }
  } else {
    url2 = config.BaseUrl + "/" + url;
  }
  return new Promise((resolve, reject) => {
    $.ajax({
      method: "get",
      url: url2,
      data: data,
      params: params,
      headers: {
        // lang:store.state.lang,
        "Accept-language": store.state.lang,
        "x-requested-with": "XMLHttpRequest",
        TOKEN: localStorage.getItem("token") ? localStorage.getItem("token") : ""
      },
      success: function (res) {
        if (res.code == 1) {
          resolve(res);
        } else {
          Message({
            message: res.msg,
            type: "error",
          });
        }
      },
      error: function (err) {
        if (err.status == 401) {
          if (!store.state.loginstore) {
            store.commit("changeloginstore", true);
            MessageBox.confirm(
              units().Loginstatushasexpiredpleaseloginagain,
              units().Systemprompt,
              {
                confirmButtonText: units().Relogin,
                showCancelButton: false,
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                closeOnHashChange: false,
              }
            ).then(() => {
              localStorage.setItem("token", "");
              if (window.location.origin) {
                window.location.href = window.location.origin + "#/login";
              } else {
                window.location.href = "#/login";
              }
              store.commit("changeloginstore", false);
            });
          }
        } else {
          if (err.status == 403) {
            if (!store.state.Refresh) {
              store.commit("changeRefresh", true);
              setTimeout(() => {
                store.commit("changeRefresh", false);
                window.location.href = window.location.origin + "#" + rules.rules().list[0];
                // location.reload();
              }, 2000);
            }
            Message({
              message: err.responseJSON.msg,
              type: "error",
            });
          } else {
            Message({
              message: units().Networkexception,
              type: "error",
            });
          }
        }
        reject(err);
      },
    });
  });
}

export function post(url, data, params) {
  let url2 = "";
  if (localStorage.getItem("token")) {
    if (params) {
      url2 = config.BaseUrl + "/" + url + "&" + Qs.stringify(params);
    } else {
      url2 = config.BaseUrl + "/" + url
    }
  } else {
    url2 = config.BaseUrl + "/" + url;
  }
  return new Promise((resolve, reject) => {
    $.ajax({
      method: "post",
      url: url2,
      data: data,
      params: params,
      headers: {
        "Accept-language": store.state.lang,
        TOKEN: localStorage.getItem("token") ? localStorage.getItem("token") : ""
      },
      success: function (res) {
        if (res.code == 1) {
          resolve(res);
        } else {
          resolve(res);
          if (typeof res != "object") {
            res = JSON.parse(res)
          }
          Message({
            message: res.msg,
            type: "error",
          });
        }
      },
      error: function (err) {
        if (err.status == 401) {
          if (!store.state.loginstore) {
            store.commit("changeloginstore", true);
            MessageBox.confirm(
              units().Loginstatushasexpiredpleaseloginagain,
              units().Systemprompt,
              {
                confirmButtonText: units().Relogin,
                showCancelButton: false,
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                closeOnHashChange: false,
              }
            ).then(() => {
              localStorage.setItem("token", "");
              if (window.location.origin) {
                window.location.href = window.location.origin + "#/login";
              } else {
                window.location.href = "#/login";
              }
              store.commit("changeloginstore", false);
            });
          }
        } else if (err.status == 403) {
          Message({
            message: units().nopermission,
            type: "error",
          });
        } else {
          if (err.status == 403) {
            if (!store.state.Refresh) {
              store.commit("changeRefresh", true);
              setTimeout(() => {
                store.commit("changeRefresh", false);
                window.location.href = window.location.origin + "#" + rules.rules().list[0];
                // location.reload();
              }, 2000);
            }
            Message({
              message: err.responseJSON.msg,
              type: "error",
            });
          } else {
            Message({
              message: units().Networkexception,
              type: "error",
            });
          }
        }
        reject(err);
      },
    });
  });
}
