export const rules = () => {
  let rules = JSON.parse(localStorage.getItem("adminNode"));
  let list = []
  let msg = {};
  let id = JSON.parse(localStorage.getItem("pid"));

  if (id === 0) {
    msg.changepassword = true;
    msg.merchantcenter = true;
    msg.administration = true;
    msg.PaymentManagement = true;
    msg.Merchant_Center = true;
    msg.financialcenter = true;
    msg.dailystatement = true;
    msg.monthlystatement = true;
    msg.orderFlow = true;
    msg.Transaction_Center = true;
    msg.transactionCenter = true;
    msg.ExclusiveChannelList = true;
    msg.cashwithdrawalrecord = true;
    msg.homepage1 = true;
    msg.homepage2 = true;
    msg.mchusersetPayConfig = true;
    msg.mchappsave = true;
    msg.mchappupdate = true;
    msg.mchappread = true;
    msg.mchappreadAppSecret = true
    msg.mchorderindex = true;
    msg.bankcard = true
    msg.wallet = true
  } else {
    msg.merchantcenter = true;
    rules.map((item) => {
      // 商户信息编辑
      if (item.name == "mch/user") {
        // 商户密码
        item.childlist.map((item2) => {
          if (item2.name == "mch/user/uppwd" && item2.checked) {
            msg.changepassword = true;
          }
        });
      }

      // 资金管理
      // if (item.name == "mch/fund") {
      //   item.childlist.map((item2) => {
      //     // 商户提现
      //     if (item2.name == "mch/mchWith") {
      //       item2.childlist.map(item3 =>{
      //         // 银行卡
      //         if(item3.name == "mch/bankcard"){
      //           msg.bankcard = true;
      //         }
      //         // 钱包
      //         if(item3.name == "mch/wallet"){
      //           msg.wallet = true;
      //         }
      //       })
      //     }
      //   });
      // }

      //   商户中心
      if (item.name == "mch") {
        msg.Merchant_Center = true;
        item.childlist.map((item2) => {
          // 商户信息
          //   if (item2.name == "mch/info" && item2.checked == true) {
          //     msg.merchantcenter = true;
          //   }
          //   应用管理
          if (item2.name == "mch/app") {
            item2.childlist.map(item3 => {
              // 应用列表
              if (item3.name == "mch/app/index" && item3.checked == true) {
                msg.administration = true;
              }
              // 应用添加
              if (item3.name == "mch/app/save" && item3.checked == true) {
                msg.mchappsave = true;
              }
              // 编辑更新 mch/app/update
              if (item3.name == "mch/app/update" && item3.checked == true) {
                msg.mchappupdate = true;
              }
              // 查看详情 mch/app/read
              if (item3.name == "mch/app/read" && item3.checked == true) {
                msg.mchappread = true;
              }
              // 查看秘钥 mch/app/readAppSecret
              if (item3.name == "mch/app/readAppSecret" && item3.checked == true) {
                msg.mchappreadAppSecret = true;
              }
            })
          }
          //   支付管理
          if (item2.name == "mch/pay") {
            item2.childlist.map((item3) => {
              // 支付管理列表
              if (item3.name == "mch/user/getPayConf" && item3.checked == true) {
                msg.PaymentManagement = true;
              }
              //   支付管理修改
              if (
                item3.name == "mch/user/setPayConfig" &&
                item3.checked == true
              ) {
                msg.mchusersetPayConfig = true;
              }
            });
          }
        });
      }

      // 财务中心
      if (item.name == "mch/finance") {
        msg.financialcenter = true;
        item.childlist.map((item2) => {
          // 月账单
          if (item2.name == "mch/order/monthBill" && item2.checked == true) {
            msg.monthlystatement = true;
          }
          //   日账单
          if (item2.name == "mch/order/dayBill" && item2.checked == true) {
            msg.dailystatement = true;
          }
          //   商户资金流水
          if (item2.name == "mch/order/flow" && item2.checked == true) {
            msg.orderFlow = true;
          }
          //   下载账单 mch/order/index
          if (item2.name == "mch/order/index" && item2.checked == true) {
            msg.mchorderindex = true;
          }
        });
      }

      //   交易中心
      if (item.name == "mch/order") {
        msg.Transaction_Center = true;
        item.childlist.map((item2) => {
          // 交易清单和专属渠道
          if (item2.name == "mch/order/index" && item2.checked == true) {
            msg.transactionCenter = true;
            msg.ExclusiveChannelList = true;
          }
          //   提现记录
          if (item2.name == "mch/transfer/index" && item2.checked == true) {
            msg.cashwithdrawalrecord = true;
          }
        });
      }

      //   首页
      if (item.name == "mch/index") {
        item.childlist.map((item2) => {
          // 首页数据
          if (item2.name == "mch/index/dashboard" && item2.checked == true) {
            msg.homepage1 = true;
          }
        });
      }

      //   仪表盘
      if (item.name == "mch/index") {
        item.childlist.map((item2) => {
          // 首页数据
          if (item2.name == "mch/dashboard/index" && item2.checked == true) {
            msg.homepage2 = true;
          }
        });
      }
    });
  }
  if (msg.homepage1 == true && msg.homepage2 == true) {
    list.push("/")
  }
  if (msg.merchantcenter) {
    list.push("/merchantcenter")
  }
  if (msg.administration) {
    list.push("/administration")
  }
  if (msg.PaymentManagement) {
    list.push("/PaymentManagement")
  }
  if (msg.transactionCenter) {
    list.push("/transactionCenter")
  }
  if (msg.ExclusiveChannelList) {
    list.push("/ExclusiveChannelList")
  }
  if (msg.cashwithdrawalrecord) {
    list.push("/cashwithdrawalrecord")
  }
  if (msg.dailystatement) {
    list.push("/dailystatement")
  }
  if (msg.monthlystatement) {
    list.push("/monthlystatement")
  }
  if (msg.orderFlow) {
    list.push("/orderFlow")
  }
  if (msg.changepassword) {
    list.push("/changepassword")
  }
  if (msg.changepassword) {
    list.push("/changepassword")
  }
  // localStorage.setItem("useradmin",JSON.stringify(msg))
  return { msg, rules, list };
};
export default {
  rules,
};
